import { parse } from 'intl-messageformat-parser'

export default {
  menu: parse('Menu'),
  search: parse('Pesquisa'),
  close: parse('Fechar'),
  'read article': parse('Ler artigo'),
  featured: parse('Destaque'),
  date: parse('Dia'),
  time: parse('Horário'),
  location: parse('Câmpus'),
  watch: parse('Assista'),
  virtual: parse('Virtuais'),
  ondemand: parse('Sob demanda'),
  inperson: parse('Presenciais'),
  tour: parse('Tour'),
  opened: parse('Open.Ed'),
  'filter articles': parse('Filtrar artigos'),
  'all categories': parse('Todas as categorias'),
  home: parse('Página inicial'),
  register: parse('Inscreva-se'),
  'back to top': parse('Voltar ao topo'),
  roles: parse('Posições em aberto'),
  'filtering-no-roles': parse('Currently displaying no positions'),
  'filtering-role': parse('Currently displaying 1 position'),
  'filtering-many-roles': parse('Currently displaying all {jobsCount} positions'),
  'filtering-filter-no-roles': parse('Currently displaying no positions'),
  'filtering-filter-role': parse('Currently displaying 1 position'),
  'filtering-filter-many-roles': parse('Currently displaying {filteredJobsCount} positions'),
  'filtering-no-filters-applied': 'No filters applied',
  'filtering-one-filter-applied': '1 filter applied',
  'filtering-many-filters-applied': '{appliedFiltersCount} filters applied',
  'filtering-clear-all-filters': 'Clear all filters',
  department: parse('Department'),
  'job function': parse('Função do cargo'),
  'all job functions': parse('Todas Função do cargo'),
  'all campuses': parse('All Campuses'),
  'Join the Waitlist': parse('Lista de espera'),
  departments: parse('Departamentos'),
  locations: parse('Locais'),
  'all departments': parse('Todos os departamentos'),
  'all offices': parse('Todos os Locais'),
  'filter roles': parse('Filtrar posições'),
  'load more': parse('Mostrar mais'),
  showMore: parse('Exibir mais'),
  showLess: parse('Exibir menos'),
  'upcoming dates': parse('Próximos eventos'),
  admissions: parse('Admissões'),
  'view details': parse('Exibir detalhes'),
  'close details': parse('Ocultar detalhes'),
  'job description': parse('Descrição do cargo'),
  'all locations': parse('Todos os locais'),

  // CITIES & PAGES
  'new york': parse('New York'),
  'são paulo': parse('São Paulo'),
  shenzhen: parse('Shenzhen'),
  about: parse('Sobre'),
  online: parse('Online'),
  careers: parse('Carreiras'),
  'open-roles': parse('Open Roles'),
  'college-counseling': parse('College Counseling'),

  // EVENT FORM
  'First Name': parse('Primeiro nome'),
  'Last Name': parse('Último Sobrenome'),
  Email: parse('Email'),
  Mobile: parse('Fone'),
  'Zip/Post Code': parse('CEP'),
  'RG/RNE/Passport ID': parse('RG/RNE/Número do Passaporte'),
  'How did you hear about Avenues?': parse('Como você conheceu a Avenues?'),
  'Avenues employee': parse('Professor/Colaborador da Avenues'),
  'Friend or family': parse('Amigo ou Família da Avenues'),
  'Online Advertising': parse('Anúncio Online'),
  'Print Advertising': parse('Anúncio Impresso'),
  'Internet Search': parse('Pesquisa na Internet'),
  'News Article': parse('Reportagem/Artigos'),
  'Relocation/Educational consultant': parse(
    'Relocation/Educational consultant ',
  ),
  Other: parse('Outros'),
  Select: parse('Selecione...'),
  Gender: parse('Sexo'),
  'Date of Birth': parse('Data de Nascimento'),
  'Entry Date': parse('Data de Ingresso'),
  January: parse('Janeiro'),
  February: parse('Fevereiro'),
  March: parse('Março'),
  April: parse('Abril'),
  May: parse('Maio'),
  June: parse('Junho'),
  July: parse('Julho'),
  August: parse('Agosto'),
  September: parse('Setembro'),
  October: parse('Outubro'),
  November: parse('Novembro'),
  December: parse('Dezembro'),
  'Guest Information': parse('Informações dos participantes'),
  Guests: parse('Participantes'),
  'Just me, no additional attendees': parse('Não levarei acompanhantes'),
  Child: parse('Filho'),
  Information: parse(' - Informações'),
  'Total Number of Additional Attendees': parse(
    'Número total de acompanhantes',
  ),

  Male: parse('Masculino'),
  Female: parse('Feminino'),
  'Prefer not to say': parse('Prefiro não dizer'),

  Month: parse('Mês'),
  Year: parse('Ano'),

  'Current school': parse('Escola Atual'),
  'Data Consent': parse('Permissão de Uso de Informações'),

  // Form Errors
  'required field': parse('Este campo é obrigatório'),
  'not valid email': parse('Informe um endereço de e-mail válido'),
  'not valid phone': parse('Informe um número de celular válido'),
  'not valid postal code': parse('Informe um código postal válido'),
  'at least 1 field required': parse('Selecione ao menos um campo'),
}
