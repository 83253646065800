import styled from 'styled-components'

export const AnimatedSlide = styled.div`
  width: 100%;
  position: relative;
  height: 3500px; // Match ScrollTrigger end value
`

export const FullHeightWrapper = styled.div`
top: 0;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  contain: layout size paint; // for better performance
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  overflow: hidden;

  .slide-background {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100vh;
    width: 100%;
    z-index: -1;
    opacity: 0.3;
    display: flex;
    align-items: center;
    justify-content: center;

    .slide-background-image {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
`

export const ParagraphTextWrapper = styled.div`
  width: 70%;

  h5 {
    color: black;
    font-family: Halyard-Book;
    font-size: min(max(1rem,6vw),48px);
    line-height: 1.3;
    text-align: left;
  }

  @media (max-width: 834px) {
    width: 100%;
    padding: 0 32px;
  }

  @media (max-width: 500px) {
    h5 {
      font-size: 28px;
      line-height: 36px;
    }
  }
`
