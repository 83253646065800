import styled from 'styled-components'
import { media } from 'utils'

export const StyledDiv = styled.div`
  h4 {
    font-family: Halyard-SemiBold !important;
    font-size: 32px;
    font-weight: normal;
    font-size: min(max(1rem, 7vw), 32px);
    margin: 32px 0 16px 0 !important;
  }

  h2 {
    font-size: 32px;
  }

  p {
    font-family: Halyard-Book !important;
    font-size: 16px !important;
    padding-bottom: 16px !important;
  }

  li {
    font-size: 18px !important;
    font-family: Halyard-Book !important;
  }

  li > p {
    
    padding-bottom: 5px !important;
  }

  ul {
    list-style-type: circle !important;
  } 

  a {
    font-weight: 600;
    border-bottom: 2px solid #248e68;
    -webkit-transition: border-color 0.2s;
    transition: border-color 0.2s;
  }
`

export const StyledFrame = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
`
export const StyledFrameDiv = styled.div`
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%; 

    ${media.greaterThan('md')`
      padding-top: 27%; 
  `}
`

export const VideoSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  row-gap: 30px;

  ${media.greaterThan('md')`
    flex-direction: row;
    column-gap: 30px;
  `}
`
