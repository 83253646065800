import { parse } from 'intl-messageformat-parser'

export default {
  menu: parse('导航'),
  search: parse('搜索'),
  close: parse('关闭'),
  'read article': parse('阅读文章'),
  featured: parse('精选'),
  date: parse('日期'),
  time: parse('时间'),
  location: parse('校区'),
  watch: parse('点击观看'),
  virtual: parse('线上'),
  ondemand: parse('爱文线上探校'),
  inperson: parse('线下'),
  tour: parse('预约参观'),
  opened: parse('Open.Ed'),
  'filter articles': parse('类别'),
  'all categories': parse('所有类别'),
  home: parse('主页'),
  register: parse('报名'),
  'back to top': parse('回到顶部'),
  roles: parse('开放职位'),
  'filtering-no-roles': parse('Currently displaying no positions'),
  'filtering-role': parse('Currently displaying 1 position'),
  'filtering-many-roles': parse('Currently displaying all {jobsCount} positions'),
  'filtering-filter-no-roles': parse('Currently displaying no positions'),
  'filtering-filter-role': parse('Currently displaying 1 position'),
  'filtering-filter-many-roles': parse('Currently displaying {filteredJobsCount} positions'),
  'filtering-no-filters-applied': 'No filters applied',
  'filtering-one-filter-applied': '1 filter applied',
  'filtering-many-filters-applied': '{appliedFiltersCount} filters applied',
  'filtering-clear-all-filters': 'Clear all filters',
  department: parse('Department'),
  'all campuses': parse('All Campuses'),
  departments: parse('部门'),
  'job function': parse('岗位类型'),
  'all job functions': parse('岗位职能'),
  locations: parse('地点'),
  'all departments': parse('所有部门'),
  'all offices': parse('所有地点'),
  'filter roles': parse('筛选职位'),
  'load more': parse('加载更多'),
  'upcoming dates': parse('活动预告'),
  admissions: parse('招生'),
  showMore: parse('展开'),
  showLess: parse('收起'),
  'view details': parse('查看详情'),
  'close details': parse('关闭详情'),
  'job description': parse('职位描述'),
  'all locations': parse('所有地点'),
  'Join the Waitlist': parse('加入候补名单'),

  // CITIES & PAGES
  'new york': parse('纽约校区'),
  'são paulo': parse('圣保罗校区'),
  Shenzhen: parse('深圳校区'),
  about: parse('关于'),
  online: parse('在线校区'),
  careers: parse('招聘'),
  'open-roles': parse('Open Roles'),
  'college-counseling': parse('College Counseling'),

  // EVENT FORM
  'First Name': parse('First Name'),
  'Last Name': parse('Last Name'),
  Email: parse('Email'),
  Mobile: parse('Mobile'),
  'Zip/Post Code': parse('Zip/Post Code'),
  'RG/RNE/Passport ID': parse('RG/RNE/Passport ID'),
  'How did you hear about Avenues?': parse('How did you hear about Avenues?'),
  'Avenues employee': parse('Avenues employee'),
  'Friend or family': parse('Friend or family'),
  'Online Advertising': parse('Online Advertising'),
  'Print Advertising': parse('Print Advertising'),
  'Internet Search': parse('Internet Search'),
  'News Article': parse('News Article'),
  'Relocation/Educational consultant': parse(
    'Relocation/Educational consultant',
  ),
  Other: parse('Other'),
  Select: parse('Select...'),
  Gender: parse('Gender'),
  'Date of Birth': parse('Date of Birth'),
  'Entry Date': parse('Entry Date'),
  January: parse('January'),
  February: parse('February'),
  March: parse('March'),
  April: parse('April'),
  May: parse('May'),
  June: parse('June'),
  July: parse('July'),
  August: parse('August'),
  September: parse('September'),
  October: parse('October'),
  November: parse('November'),
  December: parse('December'),
  'Guest Information': parse('Guest Information'),
  Guests: parse('Guests'),
  'Just me, no additional attendees': parse('Just me, no additional attendees'),
  'Child 1 Information': parse('Child 1 Information'),
  'Child 2 Information': parse('Child 2 Information'),
  'Child 3 Information': parse('Child 3 Information'),
  'Child 4 Information': parse('Child 4 Information'),
  Male: parse('Male'),
  Female: parse('Female'),
  'Prefer not to say': parse('Prefer not to say'),

  Month: parse('Month'),
  Year: parse('Year'),

  'Current school': parse('Current school'),
  'Data Consent': parse('Data Consent'),

  // Form Errors
  'required field': parse('该项必填'),
  'not valid email': parse('请提供一个有效的电子邮箱'),
  'not valid phone': parse('请提供一个有效的电话号码'),
  'not valid postal code': parse('请提供一个有效的邮政编码'),
  'at least 1 field required': parse('请选择至少一项'),
}
