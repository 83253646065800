import styled from 'styled-components'
import { media } from 'utils'
import { Container, Row } from 'components/Grid'

export const StyledContainer = styled(Container)`
  margin-bottom: 52px;
`

export const JobsListItemTitle = styled.h2`
  border-bottom: 2px solid ${({ theme }) => theme.colors.dark};
  max-width: 100%;
  line-height: 1.6;
  font-family: Halyard-Medium;
  font-size: 32px;

  ${media.greaterThan('sm')`
    padding-right: 15px;
  `}
`

export const StyledRow = styled(Row)`
  padding: inherit;
  margin-bottom: 22px;

  ${media.greaterThan('md')`
    margin-bottom: 11px;
  `}
`
