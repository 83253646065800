import React from 'react'
import { graphql } from 'gatsby'

import Components from '../components/components.js'
import SbEditable from 'storyblok-react'
import config from '../../gatsby-config'

import Layout from '../layout'
import Header from '../components/Navbar'
import Footer from '../components/Footer'

import ContentContext from 'lib/content.context'

import { parseStoryblokResults } from 'utils/helpers'

const loadStoryblokBridge = function(cb) {
  const sbConfigs = config.plugins.filter(item => {
    return item.resolve === 'gatsby-source-storyblok'
  })
  const sbConfig = sbConfigs.length > 0 ? sbConfigs[0] : {}
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.src = `//app.storyblok.com/f/storyblok-latest.js?t=${sbConfig.options.accessToken}`
  script.onload = cb
  document.getElementsByTagName('head')[0].appendChild(script)
}

const getParam = function(val) {
  var result = ''
  var tmp = []

  window.location.search
    .substr(1)
    .split('&')
    .forEach(function(item) {
      tmp = item.split('=')
      if (tmp[0] === val) {
        result = decodeURIComponent(tmp[1])
      }
    })

  return result
}

class StoryblokEntry extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      story: null,
      layout: null,
      events: [],
      news: [],
      communityNews: [],
      aonStories: [],
      opened: [],
      teamMemberProfiles: [],
      categories: props.data.allCategories.edges,
      allSmartJobs: props.data.allSmartRecruiterJobs,
    }
  }

  componentDidMount() {
    loadStoryblokBridge(() => {
      this.initStoryblokEvents()
    })
  }

  loadStory(payload) {
    window.storyblok.get(
      {
        slug: payload.storyId,
        version: 'draft',
      },
      data => {
        this.setState({ story: data.story })
        this.loadGlobalLayout(data.story.lang)
        this.loadEvents(data.story.lang)
        this.parseAndSetNews()
        this.parseAndSetCommunityNews()
        this.parseAndSetAonStories()
        this.parseAndSetOpened()
        this.parseAndSetTeamMemberProfiles()
      },
    )
  }

  loadGlobalLayout(lang) {
    const language = this.getLanguagePath(lang)
    window.storyblok.get(
      {
        slug: `${language}global/layout`,
        version: 'published',
      },
      data => {
        this.setState({ layout: data.story, lang })
      },
    )
  }

  loadEvents(lang) {
    const language = this.getLanguagePath(lang)
    window.storyblok.getAll(
      {
        version: 'draft',
        is_startpage: 0,
        starts_with: `${language}global/events`,
        per_page: 100,
        sort_by: 'content.date:asc',
      },
      data => {
        this.setState({ events: data.stories, lang })
      },
    )
  }

  parseAndSetNews() {
    this.setState({
      news: parseStoryblokResults(this.props.data.allNews.edges),
    })
  }

  parseAndSetCommunityNews() {
    this.setState({
      communityNews: parseStoryblokResults(this.props.data.allCommunityNews.edges),
    })
  }

  parseAndSetAonStories() {
    this.setState({
      aonStories: parseStoryblokResults(this.props.data.allAonStories.edges),
    })
  }

  parseAndSetOpened() {
    this.setState({
      opened: parseStoryblokResults(this.props.data.allOpenEd.edges),
    })
  }

  parseAndSetTeamMemberProfiles() {
    this.setState({
      teamMemberProfiles: parseStoryblokResults(
        this.props.data.allTeamProfiles.edges,
      ),
    })
  }

  getLanguagePath(lang) {
    return lang === 'default' ? '' : lang + '/'
  }

  initStoryblokEvents() {
    this.loadStory({ storyId: getParam('path') })

    const sb = window.storyblok

    sb.on(['change', 'published'], payload => {
      this.loadStory(payload)
    })

    sb.on('input', payload => {
      if (this.state.story && payload.story.id === this.state.story.id) {
        payload.story.content = sb.addComments(
          payload.story.content,
          payload.story.id,
        )
        this.setState({ story: payload.story })
      }
    })

    sb.pingEditor(() => {
      if (sb.inEditor) {
        sb.enterEditmode()
      }
    })
  }

  render() {
    const { events, news, aonStories, opened, communityNews, teamMemberProfiles, categories } = this.state
    if (this.state.story == null) {
      return <div />
    }

    const content = this.state.story.content
    const lang = this.state.lang || 'default'

    const layout = this.state.layout && this.state.layout.content
    const isLayoutPage = this.state.story.name === 'Layout'

    return (
      <SbEditable content={content}>
        <Layout lang={lang}>
          {!isLayoutPage &&
            layout &&
            layout.header_v2[0] &&
            React.createElement(Header, {
              key: layout.header_v2[0]._uid,
              blok: layout.header_v2[0],
              content: content,
              location: this.props.location,
              lang,
              allLanguages: ['default', 'pt', 'zh'],
            })}
          {content.component === 'event_new' ||
            content.component === 'event' ? (
              <div
                style={{
                  height: '12vh',
                  width: '100vw',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#F5CD64',
                  marginTop: '180px',
                }}
              >
                <div>
                  <p>
                    <strong>Event name for HubSpot:</strong>
                  </p>
                </div>
                <div>
                  <p>
                    <strong>
                      {content.location} {content.date}
                    </strong>
                  </p>
                </div>
              </div>
            ) : (
              ''
            )}
          {Components[content.component] ? (
            <ContentContext.Provider
              value={{
                allEvents: events,
                allNews: news,
                allCommunityNews: communityNews,
                allAonStories: aonStories,
                allCategories: categories,
                allSmartJobs: this.state.allSmartJobs,
                allTeamProfiles: teamMemberProfiles,
                allOpenEd: opened,
              }}
            >
              {React.createElement(Components[content.component], {
                key: content._uid,
                blok: content,
                location: this.props.location,
                lang,
                allLanguages: ['default', 'pt', 'zh'],
              })}
            </ContentContext.Provider>
          ) : (
            `Component ${content.component} not created yet`
          )}
          {!isLayoutPage &&
            layout &&
            layout.footer[0] &&
            React.createElement(Footer, {
              key: layout.footer[0]._uid,
              blok: layout.footer[0],
            })}
        </Layout>
      </SbEditable>
    )
  }
}

export default StoryblokEntry

export const query = graphql`
  # query will go here
  query EditorPageQuery {
    layout: allStoryblokEntry(filter: { name: { eq: "Layout" } }) {
      edges {
        node {
          name
          content
          lang
        }
      }
    }
    #
    # CATEGORIES
    #
    allCategories: allStoryblokDatasource(
      filter: { data_source: { eq: "categories" } }
    ) {
      edges {
        node {
          name
          value
        }
      }
    }
    #
    # NEWS
    #
    allNews: allStoryblokEntry(
      filter: {
        field_component: { eq: "news" }
        slug: { ne: "single-news" }
        lang: { eq: "default" }
      }
      sort: { fields: first_published_at, order: DESC }
    ) {
      edges {
        node {
          content
          slug
          full_slug
          uuid
          lang
        }
      }
    }
          ### Smart Recruiter
    allSmartRecruiterJobs {
      edges {
        node {
          customField {
            fieldId
            fieldLabel
            valueId
            valueLabel
          }
          experienceLevel {
            label
          }
          id
          jobId
          jobAdId
          location {
            city
            region
            remote
          }
          name
          refNumber
          typeOfEmployment {
            label
          }
        }
      }
    }
    #
    # Team Member Profiles
    #
    allTeamProfiles: allStoryblokEntry(
      filter: {
        field_component: { eq: "team_member_profile" }
        lang: { eq: "default" }
      }
      sort: { fields: first_published_at, order: DESC }
    ) {
      edges {
        node {
          content
          slug
          full_slug
          uuid
          lang
        }
      }
    }
    allOpenEd: allStoryblokEntry(
      filter: {
        field_component: { eq: "opened" }
        slug: { ne: "single-community-news" }
        lang: { eq: "default"}
      }
    ) {
      edges {
        node {
          content
          slug
          full_slug
          uuid
          lang
          first_published_at
          id
        }
      }
    }
    allCommunityNews: allStoryblokEntry(
      filter: {
        field_component: { eq: "community_news" }
        slug: { ne: "single-community-news" }
        lang: { eq: "default" }
      }
    ) {
      edges {
        node {
          content
          slug
          full_slug
          uuid
          lang
          id
        }
      }
    }
    allAonStories: allStoryblokEntry(
      filter: {
        field_component: { eq: "aon_story" }
        slug: { ne: "single-community-news" }
        lang: { eq: "default" }
      }
    ) {
      edges {
        node {
          content
          slug
          full_slug
          uuid
          lang
          id
        }
      }
    }
  }
`
