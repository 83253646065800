import React, { useLayoutEffect, useRef, useEffect } from 'react'
import { useTheme } from 'styled-components'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import MapLight from '/static/img/map-light.svg'

import {
  AnimatedSlide,
  FullHeightWrapper,
  ParagraphTextWrapper,
} from './styles'

gsap.registerPlugin(ScrollTrigger)

export default function FullHeightSlide({ blok, index }) {
  const theme = useTheme()
  const FHSlideApp = useRef()

  useEffect(() => {
    function handleResize() {
      ScrollTrigger.refresh()
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      gsap
        .timeline({
          scrollTrigger: {
            scrub: true,
            // markers: true,
            trigger: '.full-height-wrapper',
            start: 'top top',
            end: '3500px',
            refreshPriority: -index,
          },
        })
        .fromTo(
          '.full-height-wrapper',
          {
            backgroundColor: '#FFFFFF',
            ease: 'none',
          },
          {
            duration: 2,
            backgroundColor: theme.colors[blok.background_color],
            ease: 'none',
          },
        )
        .fromTo(
          '.slide-background',
          { height: 0, width: 0, ease: 'easeIn' },
          { duration: 2, height: '100%', width: '100%', ease: 'easeIn' },
        )
        .from('.paragraphText', { duration: 4, opacity: 0, y: '200px' }, '<25%')
        .to('.paragraphText', { duration: 4, opacity: 0, y: '-200px' }, '>4')
        .fromTo(
          '.slide-background',
          { height: '100%', width: '100%', ease: 'easeIn' },
          { duration: 2, height: 0, width: 0, ease: 'easeIn' },
          '<25%',
        )
        .to(
          '.full-height-wrapper',
          {
            duration: 2,
            backgroundColor: '#FFFFFF',
            ease: 'none',
          },
          '<25%',
        )
    }, FHSlideApp)

    return () => ctx.revert()
  }, [])

  return (
    <>
      <AnimatedSlide ref={FHSlideApp}>
        <FullHeightWrapper className="full-height-wrapper">
          <ParagraphTextWrapper className="paragraphTextWrapper">
            <h5 className="paragraphText">{blok.text}</h5>
            <div className="slide-background">
              <img
                className="slide-background-image"
                src={MapLight}
                alt="pattern of dots that form the shape of a world map"
              />
            </div>
          </ParagraphTextWrapper>
        </FullHeightWrapper>
      </AnimatedSlide>
    </>
  )
}
