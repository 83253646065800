import React, { useState, useContext } from 'react'
import JobsFilters from './components/JobsFilters'
import JobsList from './components/JobsList'
import useSearchConfig from './hooks/useSearchConfig'
import { StyledSection } from './styles'

import ContentContext from 'lib/content.context'

export default function NewOpenRoles({ blok, lang, location }) {
  const { search_delay } = blok

  const [filteredJobs, setFilteredJobs] = useState(null)

  const searchConfig = useSearchConfig({ search_delay })

  return (
    <StyledSection>
      <JobsFilters
        // Remount component if there is a new version of search config
        key={searchConfig.version}
        searchConfig={searchConfig}
        onChange={setFilteredJobs}
        pageLocation={location}
      />
      <JobsList filteredJobs={filteredJobs} lang={lang} pageLocation={location} />
    </StyledSection>
  )
}
